export const customClass = "c1x5jk2m";
export const container = "c10go1mf";
export const buttonContainer = "b1hfqjtx";
export const imageContainer = "i72lkft";
export const heading = "hsn6lkl";
export const subheading = "s1cfqsdd";
export const separator = "sbduyfn";
export const orText = "o160a3c4";
export const button = "bi1fcb1";
export const closeButton = "c16lfgy2";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");