import AuthDialog from '@/components/AuthDialog'
import SurveyDialog from '@/components/SurveyDialog'
import { useAuth } from '@/context/auth'
import { getConfig } from '@/lib/config'
import { createContext, ReactNode, useContext, useEffect, useMemo, useReducer } from 'react'

export const isSurveyDialogShownKey = 'is-survey-dialog-shown'

function isSurveyDialogShown(): boolean {
  if (typeof window === 'undefined') return false
  const isSurveyDialogShown = localStorage.getItem(isSurveyDialogShownKey)
  return typeof isSurveyDialogShown === 'string' ? JSON.parse(isSurveyDialogShown) : false
}

type ActionType = {
  type: 'TOGGLE_AUTH_DIALOG' | 'TOGGLE_SURVEY_DIALOG'
}

type ContextType = {
  showAuthPrompt: boolean
  showSurveyPrompt: boolean
  toggleAuthPrompt: () => void
  toggleSurveyPrompt: () => void
}

const contextDefaultValues: ContextType = {
  showAuthPrompt: false,
  showSurveyPrompt: false,
  toggleAuthPrompt: () => {},
  toggleSurveyPrompt: () => {},
}

const AuthPromptContext = createContext<ContextType>(contextDefaultValues)

type Props = {
  children: ReactNode
  disableAuthPrompt?: boolean
}

const { publicRuntimeConfig } = getConfig()

function authPromptReducer(state: ContextType, action: ActionType): ContextType {
  switch (action.type) {
    case 'TOGGLE_AUTH_DIALOG':
      return {
        ...state,
        showAuthPrompt: !state.showAuthPrompt,
      }
    case 'TOGGLE_SURVEY_DIALOG':
      return {
        ...state,
        showSurveyPrompt: !state.showSurveyPrompt,
      }

    default:
      return state
  }
}

export function AuthPromptProvider({ children, disableAuthPrompt = false }: Props): JSX.Element {
  const [state, dispatch] = useReducer(authPromptReducer, contextDefaultValues)
  const {
    state: { user },
  } = useAuth()

  const contextValue = useMemo(
    () => ({
      showAuthPrompt: state.showAuthPrompt,
      showSurveyPrompt: state.showSurveyPrompt,
      toggleAuthPrompt: () => {
        dispatch({ type: 'TOGGLE_AUTH_DIALOG' })
      },
      toggleSurveyPrompt: () => {
        dispatch({ type: 'TOGGLE_SURVEY_DIALOG' })
      },
    }),
    [state.showAuthPrompt, state.showSurveyPrompt],
  )

  useEffect(() => {
    if (user?.isVerified || disableAuthPrompt) {
      return
    }

    const timer = setTimeout(() => {
      contextValue.toggleAuthPrompt()
    }, publicRuntimeConfig.dialogs.auth.delay)

    return () => clearTimeout(timer)
  }, [user])

  useEffect(() => {
    if (state.showAuthPrompt || disableAuthPrompt || isSurveyDialogShown()) return
    const timer = setTimeout(() => {
      contextValue.toggleSurveyPrompt()
    }, publicRuntimeConfig.dialogs.survey.delay)

    return () => clearTimeout(timer)
  }, [state.showAuthPrompt])

  return (
    <AuthPromptContext.Provider value={contextValue}>
      {state.showAuthPrompt && <AuthDialog />}
      {state.showSurveyPrompt && <SurveyDialog />}
      {children}
    </AuthPromptContext.Provider>
  )
}

export function useAuthPrompt(): ContextType {
  const context = useContext(AuthPromptContext)
  if (context === undefined) {
    throw new Error('useAuthPrompt must be used within a AuthPromptProvider')
  }
  return context
}
