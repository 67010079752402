import Dialog from '@/components/Dialog'
import { isSurveyDialogShownKey, useAuthPrompt } from '@/context/authPrompt'
import { useEffect } from 'react'
import * as styles from './style'

export default function SurveyDialog(): JSX.Element {
  const { toggleSurveyPrompt } = useAuthPrompt()

  function handleClick(): void {
    toggleSurveyPrompt()
  }

  useEffect(() => {
    localStorage.setItem(isSurveyDialogShownKey, JSON.stringify(true))
  }, [])

  return (
    <Dialog customClass={styles.customClass}>
      <div className={styles.container}>
        <span onClick={toggleSurveyPrompt} className={styles.closeButton}>
          <img src="/icons/close.svg" width="18px" />
        </span>
        <a href="https://form.typeform.com/to/imp7sJqO" target="_blank" onClick={handleClick}>
          <div className={styles.imageContainer}>
            <img src="https://gsuimages.com/events/annual-survey-2024.png" />
          </div>
        </a>
      </div>
    </Dialog>
  )
}
